import './styles.css';

import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from 'react-router-dom';
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  useUser,
} from '@clerk/clerk-react';
import { Rooms, Room, Admin } from './components/Room';
import { AboutPage } from './pages/About';
import { HomePage } from './pages/Home';

declare const CLERK_PUBLISHABLE_KEY: string;

if (!CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}
// window.PARTYKIT_HOST = import.meta.url.includes('localhost')
//   ? 'ws://localhost:1999'
//   : // eslint-disable-next-line no-undef
//     'ws://react-vite-example.newtriks.partykit.dev';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: 'about',
    element: <AboutPage />,
  },
  {
    path: 'room',
    element: (
      <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY}>
        <SignedOut>
          <RedirectToSignIn />
        </SignedOut>
        <SignedIn>
          <Rooms />
        </SignedIn>
      </ClerkProvider>
    ),
    children: [
      {
        path: ':roomId',
        element: <Room />,
      },
      {
        path: 'admin',
        element: <Admin />,
      },
    ],
  },
]);

createRoot(document.getElementById('app')!).render(
  <RouterProvider router={router} />
);
